import axios from '@/axios-instance'

/**
 * Merge the translations to the results so that the current language attributes are available
 * on the top level.
 */
const mergeActiveTranslationToResults = (survey, currentLanguage) => {
    const translationsWithCurrentLanguage = survey.translations.filter((translation) => translation.locale === currentLanguage)
    const activeTranslation = translationsWithCurrentLanguage.length > 0 ?
        translationsWithCurrentLanguage[0] :
        survey.translations[0]
    return { ...survey, ...activeTranslation }
}

export const ModuleResults = {
    namespaced: true,

    state: {
        currentLanguage: 'fi',
        userSurveyData: {},
        orgSurveyData: {},
        groupSurveyData: {},
        survey360Data: {},
        roleSurvey360Data: {}
    },

    mutations: {
        setLang(state, lang) {
            state.currentLanguage = lang

            // refresh our internal data
            if (Object.keys(state.userSurveyData).length > 0) {
                Object.keys(state.userSurveyData).map((surveyId) => {
                    Object.keys(state.userSurveyData[surveyId]).map((userId) => {
                        this.commit(
                            "results/setUserSurveyAnswers",
                            {surveyId, userId, data: state.userSurveyData[surveyId][userId]}
                        )
                    })
                })
            }
            if (Object.keys(state.orgSurveyData).length > 0) {
                Object.keys(state.orgSurveyData).map((surveyId) => {
                    Object.keys(state.orgSurveyData[surveyId]).map((orgId) => {
                        this.commit(
                            "results/setOrgSurveyAnswers",
                            {surveyId, orgId, data: state.orgSurveyData[surveyId][orgId]}
                        )
                    })
                })
            }
            if (Object.keys(state.groupSurveyData).length > 0) {
                Object.keys(state.groupSurveyData).map((surveyId) => {
                    Object.keys(state.groupSurveyData[surveyId]).map((groupId) => {
                        this.commit(
                            "results/setOrgGroupSurveyAnswers",
                            {surveyId, groupId, data: state.groupSurveyData[surveyId][groupId]}
                        )
                    })
                })
            }
        },

        setUserSurveyAnswers(state, { surveyId, userId, data }) {
            if (state.userSurveyData[surveyId] === undefined) {
                state.userSurveyData[surveyId] = {}
            }
            state.userSurveyData[surveyId][userId] = mergeActiveTranslationToResults(data, state.currentLanguage)
        },

        setOrgSurveyAnswers(state, { orgId, surveyId, data }) {
            if (state.orgSurveyData[surveyId] === undefined) {
                state.orgSurveyData[surveyId] = {}
            }
            state.orgSurveyData[surveyId][orgId] = mergeActiveTranslationToResults(data, state.currentLanguage)
        },

        setOrgGroupSurveyAnswers(state, { groupId, surveyId, data }) {
            if (state.groupSurveyData[surveyId] === undefined) {
                state.groupSurveyData[surveyId] = {}
            }
            state.groupSurveyData[surveyId][groupId] = mergeActiveTranslationToResults(data, state.currentLanguage)
        },

        setRoleSurvey360Answers(state, { surveyId, role, data }) {
            if (state.roleSurvey360Data[surveyId] === undefined) {
                state.roleSurvey360Data[surveyId] = {}
            }
            state.roleSurvey360Data[surveyId][role] = data
        },

        setSurvey360Answers(state, {surveyId, data}){
            if (state.survey360Data[surveyId] === undefined) {
                state.survey360Data[surveyId] = {}
            }
            state.survey360Data[surveyId]['all'] = data
        }
    },

    actions: {
        fetchUserSurveyAnswers({ commit }, { surveyId, userId }) {
            axios
                .get(`/api/v1/surveys/results/${surveyId}/?user=${userId}`, { timeout: 10000 })
                .then((res) => {
                    commit('setUserSurveyAnswers', { userId, surveyId, data: res.data })
                }).catch((e) => {
                    console.log(e)
                })
        },
        fetchOrgSurveyAnswers({ commit }, { surveyId, orgId, groupId }) {
            const url = groupId !== undefined && groupId !== null ?
                        `/api/v1/surveys/results/${surveyId}/?group=${groupId}` :
                        `/api/v1/surveys/results/${surveyId}/?org=${orgId}`
            axios
                .get(url, { timeout: 5000000 })
                .then((res) => {
                    if( groupId !== undefined ) {
                        commit('setOrgGroupSurveyAnswers', { groupId, surveyId, data: res.data })
                    } else {
                        commit('setOrgSurveyAnswers', { orgId, surveyId, data: res.data })
                    }
                }).catch((e) => {
                    console.log(e)
                })
        },
        fetchSurvey360AnswersByRole({ commit }, {surveyId, role}) {
            axios
                .get(`/api/v1/surveys/360/${surveyId}/results/?role=${role}`, { timeout: 5000 })
                .then((res) => {
                    commit('setRoleSurvey360Answers', { role, surveyId, data: res.data })
                }).catch((e) => {
                    console.log(e)
                })
        },
        fetchSurvey360Answers({ commit }, {surveyId}) {
            axios
                .get(`/api/v1/surveys/360/${surveyId}/results/`, { timeout: 5000 })
                .then((res) => {
                    commit('setSurvey360Answers', { surveyId, data: res.data })
                }).catch((e) => {
                    console.log(e)
                })
        },
        fetchTargetSurvey360Answers({ commit }, {surveyId}) {
            axios
                .get(`/api/v1/surveys/360/${surveyId}/target_results/`, { timeout: 5000 })
                .then((res) => {
                    commit('setSurvey360Answers', { surveyId, data: res.data })
                }).catch((e) => {
                    console.log(e)
                })
        },
    }
}
