import axios from '@/axios-instance'
import router from '@/router'

export const getAccessToken = () => {
  return localStorage.getItem('access')
}

export const loggedIn = () => {
  return !!getAccessToken()
}

export const getRefreshToken = () => {
  return localStorage.getItem('refresh')
}

export const setAccessToken = (token) => {
  return localStorage.setItem('access', token)
}

export const setRefreshToken = (token) => {
  return localStorage.setItem('refresh', token)
}

export const flushTokens = () => {
  return Promise.resolve().then(function () {
    localStorage.removeItem('access')
    localStorage.removeItem('refresh')
  })
}
const isUserAdmin = (user) => {
  return (user && user.type) ? ['org_admin', 'coach', 'admin'].indexOf(user.type['type']) > -1 : false
}
export const ModuleUser = {
  namespaced: true,
  state: {
    token: null,
    loginError: false,
    signupError: false,
    loginErrorMessage: null,
    loggedIn: false,
    signupErrorMessage: null,
    user: null,
    userAnswers: null,
    userSurveyTokens: null,
    user360Surveys: null,
  },
  getters: {
    getToken: (state) => state.token,

    getLoginError: (state) => state.loginError,

    getLoginErrorMessage: (state) => state.loginErrorMessage,

    getSignupError: (state) => state.signupError,

    getSignupErrorMessage: (state) => state.signupErrorMessage,

    // Later on validate token via API call!
    loggedInStatus: (state) => {
      if (process.browser) {
        return state.loggedIn || getAccessToken()
      }
    },

    isAdminUser: (state) => isUserAdmin(state.user),

    getUser: (state) => state.user,
  },
  mutations: {
    setLogin(state, data) {
      console.info("setLogin() called")
      state.loginError = false
      state.signupError = false
      state.loggedIn = true
      setRefreshToken(data['refresh'])
      setAccessToken(data['access'])
      state.token = data
    },
    setLoginError(state, data) {
      state.loginError = true
      state.loggedIn = false
      state.loginErrorMessage = data
    },
    setSignupError(state, errors) {
      state.signupError = true
      state.loggedIn = false
      state.signupErrorMessage = errors
    },
    setUser(state, data) {
      state.user = data
    },
    setUserAnswers(state, data) {
      state.userAnswers = data
    },
    setUserSurveyTokens(state, data) {
      state.userSurveyTokens = data
    },
    setUser360Surveys(state, data) {
      state.user360Surveys = data
    },
  },
  actions: {
    async signup(context, data) {
      return flushTokens().then(() =>
        axios.post('/api/v1/organizations/user/accepts-invitation', data)
          .then((res) => {
            const tokens = res.data
            console.info("successful signup, assigning tokens")
            context.commit('setLogin', tokens)
          })
          .then(() => {
            return context.dispatch('getUser')
          })
          .catch((err) => {
            context.commit('setSignupError', err.response.data['message'])
          })
      )
    },
    async signin(context, data) {
      return flushTokens().then(() =>
        axios.post('/auth/token/', data)
          .then((res) => {
            if (res) {
              const tokens = res.data
              context.commit('setLogin', tokens)
            } else {
              context.commit('setLoginError', 'Please re-check your password and username')
            }
          })
          .then(() => {
            return context.dispatch('getUser')
          })
          .catch((err) => {
            context.commit('setLoginError', err.response.data.detail)
          })
      )
    },

    async logout(context) {
      await axios.post('/auth/user/clear_cookie/')
      context.commit('setUser', null)
      flushTokens().then(() => router.push('/login'))
    },

    getUser(context) {
      return axios.get('/auth/user/')
        .then((res) => {
          console.info('getUser()')
          const user = res.data
          context.commit('setUser', user)
          return user
        })
        .then(user => {
          if (isUserAdmin(user)) {
            context.dispatch('organizations/fetchOrganizations', null, { root: true })
          }
          return user
        }).catch((err) => {
          console.log('Get user error ' + err)
        })
    },

    fetchUser(context) {
      if (context.state.user) return
      return axios.get('/auth/user/')
        .then((res) => {
          const user = res.data
          context.commit('setUser', user)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchUserAnswers(context, _data) {
      return axios.get(`/api/v1/surveys/user_answers/${_data['usr']}/`).
        then((res) => {
          const data = res.data
          context.commit('setUserAnswers', data)
        })
        .catch(async (err) => {
          if (_data['redirectOn403'] && err.response.status === 403) {  // accessing something one can't
            await router.push({ name: 'PageNotFound' })
          }
          console.log(err)
        })
    },

    fetchUserSurveyTokens(context, usr) {
      return axios.get(`/api/v1/surveys/tokens/${usr['usr']}/`).
        then((res) => {
          const data = res.data
          context.commit('setUserSurveyTokens', data)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    fetchUserAccepted360Surveys(context) {
      return axios.get(`/api/v1/surveys/360/target_surveys/`).
        then((res) => {
          const data = res.data
          context.commit('setUser360Surveys', data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  },
}
