{
  "common": {
    "dashboard": "Etusivu",
    "survey": "Arviointi",
    "print": "Tulosta"
  },
  "dashboard": {
    "hello": "Tervetuloa Selfleader.io-palveluun",
    "learn": "Lue lisää palvelusta",
    "greetings": "Mukava nähdä, {name}!",
    "topic1": "Ryhmiä organisaatiossa {org}",
    "topic2": "Ryhmät",
    "new_group": "Luo uusi ryhmä",
    "survey": "Arviot",
    "send_survey": "Lähetä arvioita",
    "view": "Katso",
    "survey_table_name": "Nimi",
    "survey_table_sent_to": "Lähetetty",
    "survey_table_answered": "Vastanneita",
    "invite_people": "Kutsu vastaajia",
    "invite_center": "Kutsukeskus",
    "group_description": "{members_count} jäsentä, {invited_count} kutsuttu yhteensä",
    "invited_total": "Kutsuttu yhteensä",
    "members": "Jäsenet",
    "invited": "Kutsutut",
    "view_members": "Katso Jäsenet",
    "invitations": "Kutsut",
    "send_invitation_reminders": "Lähetä kutsumuistutuksia",
    "user_email": "Käyttäjän sähköposti",
    "invitation_status": "Kutsun tila",
    "first_email_sent_at": "Ensimmäinen sähköposti lähetetty osoitteeseen",
    "reminder_sent_at": "Muistutus lähetetty klo",
    "send_invitations": "Lähetä kutsumuistutus",
    "invite": "Muistuta",
    "okay": "Okei",
    "reminder_successful": "Kutsumuistutukset lähetettiin onnistuneesti.",
    "organization_group_name": "Organisaatioryhmän nimi",
    "survey_table_date": "Päivämäärä",
    "survey_details": "Kyselyn tiedot",
    "user_name": "käyttäjätunnus",
    "survey_sent": "kysely lähetetty",
    "survey_status": "Kyselyn tila",
    "send_survey_reminder": "Lähetä kyselymuistutus",
    "send_survey_reminders": "Lähetä kyselymuistutuksia",
    "survey_name": "kyselyn nimi",
    "survey_360": "Survey 360",
    "scheduled": "Ajastetut",
    "download_org_report": "Lataa PowerPoint",
    "no-reminders-sent": "Ei lähetettyjä muistutuksia"
  },
  "invite": {
    "invite_text": "Kutsu kollegasi",
    "invite_text2": "Anna lista sähköposteja ja lähetämme kutsun kollegoillesi tulla mukaan palveluun. Yksi sähköposti per rivi, kiitos.",
    "emails": "Sähköpostit",
    "emails_sub_text": "Yksi sähköposti per rivi",
    "invite_in_group": "Kutsu ryhmään",
    "new_group_will_be_created": "Ryhmä {newGroup} luodaan automaattisesti",
    "group_select_placeholder": "Valitse ryhmä, johon kutsutut ihmiset lisätään. Voit valita olemassaolevista tai kirjoittaa ryhmän nimen luodaksesi uuden.",
    "invitation_button": "Lähetä kutsut",
    "emails_error1": "Ole hyvä ja lisää yksi tai enemmän sähköposteja.",
    "emails_error2": "Ole hyvä ja tarkista sähköposti(t), jotkut niistä taitavat olla viallisia.",
    "selected_group_missing": "Please select or add a new group for the new user(s).",
    "emails_success": "Kutsut lähetetty onnistuneesti.",
    "success": "Onnistui",
    "starting_survey": "Aloituskysely",
    "survey_sub_text": "Tämä kysely lähetetään käyttäjille heti heidän liityttyään palveluun",
    "no_survey_selected": "Ei aloituskyselyä",
    "new_group": "Uusi ryhmä",
    "existing_group": "Olemassaoleva ryhmä",
    "email_greeting": "Tervehdys",
    "email_greeting_help": "Vaihtoehtoinen viesti, joka lisätään osaksi ensimmäistä sähköpostia",
    "email_sending_failed": "Sähköpostin lähettäminen osoitteeseen epäonnistui ",
    "please_check_addresses": "Tarkista osoitteet",
    "already_members": "Jo jäseniä",
    "invitation_already_sent": "Kutsu on jo lähetetty näihin sähköposteihin",
    "errors": "virheitä lähetettäessä kutsuja:",
    "following": "Seurata ",
    "invite_multiple_members": "Etsi vastaanottajia organisaation jäsenistä tai lisää heidät sähköpostitse",
    "send_out_surveys": "Lähetä '{surveyName}' kysely {number} vastaanottajalle. He saavat sähköpostiinsa linkin, jonka avulla he voivat vastata arvioon",
    "email_send_successful": "Lähetetty onnistuneesti",
    "schedule_success_message": "Kutsu(t) on ajoitettu",
    "schedule_update": "Kutsut ajoitetaan",
    "schedule_update_heading": "Päivitetty (peruuta painamalla x)",
    "schedule_tooltip": "Aseta kutsujen aikataulu"
  },
  "signup": {
    "message": "<p>Oikealta ylhäältä pääset vaihtamaan vastauskieltä.</p> <p>Luo itsellesi käyttäjätili ja saat pääsyn Selfleaderin materiaaliin, arvioihin ja tuloksiisi.</p><ol><li>Lisää oma etunimesi sekä sukunimesi</li><li>Valitse itsellesi sopiva salasana </li><li>Seuraavalla kerralla kirjautuessasi sähköpostisi toimii käyttäjätunnuksenasi sekä nyt valitsemasi salasana. Otathan salasanasi talteen. </li></ol>"
  },
  "login": {
    "no_account": "Eikö sinulla ole vielä käyttäjätunnusta?",
    "forget_password": "Unohtuiko salasana? Ei hätää.",
    "new_password": "Saat uuden salasanan tätä kautta.",
    "login_text": "Kirjaudu sisään",
    "office_login": "Kirjaudu sisään Office 365:n avulla",
    "email": "Sähköposti",
    "password": "Salasana",
    "first_name": "Etunimi",
    "last_name": "Sukunimi",
    "error1": "Ole hyvä ja kirjoita toimiva sähköpostiosoite.",
    "error2": "Ole hyvä ja täytä kaikki vaaditut kentät."
  },
  "password_reset": {
    "reset_text": "Nollaa salasana",
    "reset_heading": "Nollaa salasana",
    "new_password_heading": "Syötä uusi salasana",
    "confirm_text": "Vahvista",
    "validation_error": "Tunnus on vanhentunut, virheellinen tai käytetty.",
    "empty_error": "Salasana ei voi olla tyhjä",
    "success_message": "Salasanan palautusviesti on lähetetty sähköpostiisi",
    "done": "Tehty",
    "success_button": "Kirjaudu sisään"
  },
  "members": {
    "members": "Jäsenet",
    "invite": "Kutsu uusia jäseniä",
    "survey": "Arviot",
    "send_survey": "Lähetä arvioita",
    "survey_table_name": "Nimi",
    "survey_table_sent_to": "Lähetetty",
    "survey_table_answered": "Vastanneita"
  },
  "survey": {
    "results": "VASTAUKSET",
    "answers": "VASTAAJAT",
    "invited": "KUTSUTUT",
    "send_to_more": "Lähetä useammille ihmisille",
    "no_data": "Ei vastauksia",
    "summary": "Arvion tulokset",
    "strongest": "Vahvimmat",
    "weakest": "Heikoimmat",
    "all_members": "Kaikki organisaation käyttäjät",
    "add_recipients": "Lisää",
    "selected_recipients": "valitut vastaanottajat",
    "no_recipients": "Ei valittuja vastaanottajia"
  },
  "send_survey": {
    "send_survey_text": "Lähetä arvio",
    "survey": "Arvio",
    "survey_questions": "'{survey_name}' sisältää {total_questions} kysymystä",
    "recipients": "Vastaanottajat",
    "group": "Ryhmä",
    "greeting_text_message": "Tämä viesti lisätään lähetettävään sähköpostiin.",
    "no_members": "Ei jäseniä valitussa ryhmässä",
    "send_surveys": "Lähetä arviot",
    "no_member_selected": "Ei valittuja jäseniä",
    "success_message": "Arvio lähetetty!",
    "success_text": "Vastaanottajasi saavat kohta linkin sähköpostiinsa. Voit seurata kuka on vastannut palvelun dashboard-sivun kautta.",
    "success_button_text": "Vie minut dashboardiin",
    "all_members": "Kaikki Jäsenet",
    "limited_to_groups": "Rajoita ryhmiin",
    "already_in_selected_recipients": "Tämä jäsen on jo Valitut vastaanottajat -luettelossa",
    "additional_message": "Lisäviesti",
    "additional_message_subtext": "Tämä viesti lisätään sähköpostiin",
    "available": "saatavilla olevat vastaanottajat",
    "new_user": "uusi käyttäjä"
  },
  "404": {
    "page_not_found": "oops! Sivua ei löytynyt",
    "page_not_exists": "Oops! Sivu jota hait ei ole olemassa. Se on saatettu siirtää tai tuhota."
  },
  "answer_survey": {
    "submit": "Lähetä vastaukset",
    "no_survey": "Pahoittelut, arvio ei ole saatavilla",
    "thanks": "Kiitos arvion täyttämisestä",
    "go_frontpage": "Siirry palvelun etusivulle"
  },
  "user_results": {
    "target_360_surveys": "360 johtajuusarviot",
    "your_surveys": "Vastatut arviosi",
    "received_invitations": "Saadut arviokutsut",
    "answer": "Vastaa arvioon",
    "answered_surveys": "Käyttäjän {name} täyttämät arviot",
    "no_answered_surveys": "Ei vastauksia",
    "no_tokens": "Ei arviokutsuja saatavilla tällä hetkellä.",
    "no_survey": "Vastaamasi arviot ja kutsut uusiin ilmaantuvat tähän. Ei tarvitse kuitenkaan käydä jatkuvasti kurkkimassa, sillä lähetämme kutsut myös postiisi.",
    "no_survey_for_admin": "Tämä käyttäjä ei ole vastannut arvioihin vielä.",
    "survey": "Arvio: ",
    "answers": "Vastaukset",
    "amount_open_answers": "{amount} avoimia vastauksia",
    "chosen": "Valinta:",
    "promoters": "Suosittelijat",
    "passives": "Passiiviset",
    "detractors": "Arvostelijat",
    "your_tasks_to_do": "Suoritettavat tehtävät",
    "active_goals": "Toistuvat harjoitteet",
    "mark_task_incomplete": "Merkkaa tehtävä suorittamattomaksi",
    "mark_goal_incomplete": "Merkkaa tavoite suorittamattomaksi",
    "mark_task_complete": "Merkkaa tehtävä suoritetuksi",
    "mark_goal_complete": "Merkkaa tavoite saavutetuksi",
    "goal_history": "Historia",
    "task_instructions": "Ole hyvä ja anna palautetta tehtävän suorittamisesta. Tämä näkyy sinulle ja valmentajallesi.",
    "goal_instructions": "Ole hyvä ja anna palautetta tavoitteen saavuttamisesta. Tämä näkyy sinulle ja valmentajallesi.",
    "you_completed_task": "Suoritit tämän tehtävän <strong>{completedAt}</strong>. Kommentein:",
    "no_goal_completions": "Et ole vielä saavuttanut tavoitetta kertaakaan.",
    "no_goals_available_yet": "Ei asetettuja tavoitteita",
    "no_tasks_available_yet": "Ei määrättyjä tehtäviä"
  },
  "survey360": {
    "answerResponderString": "Vastaaja: { name } { email } roolissa { role }",
    "answerParticipantString": "Arvio koskee henkilöä: { name } organisaatiosta: { organization }",
    "dropdown_text": "Rooli",
    "details_dropdown_text": "Kaikki",
    "target_user": "Kohdekayttaja"
  },
  "navigation": {
    "organization": "Organisaatio",
    "invite": "Kutsu lisää jäseniä",
    "allMembers": "Jäsenet",
    "members": "Ryhmän jäsenet",
    "member-results": "Ryhmän tulokset",
    "surveyResults": "Arvion tulokset",
    "survey-reminder": "Lähetä muistutuksia",
    "userResults": "Oma sivu",
    "sendSurvey": "Lähetä arvio",
    "surveyView": "Arvio"
  },
  "data-protection": {
    "title": "Tietosuojaseloste"
  },
  "invitations": {
    "title": "Lähetä kutsuja ja tehtäviä",
    "survey": {
      "title": "Arvio",
      "ingress": "Kutsu käyttäjät täyttämään tämän arvion. Uusien jäsenten tulee luoda tili ennen arvion täyttämistä.",
      "additional-message": "Arvioon liitettävä viesti",
      "additional-message-info": "Tämä viesti lisätään lähetettävään sähköpostiin",
      "select": "Valitse arvio"
    },
    "goalsandtasks": {
      "title": "Tehtävät ja harjoitteet",
      "ingress": "Voit myös lisätä käyttäjille tehtäviä ja harjotteita. Uusien jäsenten tulee luoda tili ensiksi.",
      "selected": "Valitut tehtävät ja harjoitteet",
      "goals": {
        "title": "Harjoite",
        "add": "Lisää harjoite",
        "select": "Valitse harjoite",
        "info": "'{name}' harjoite toistuu {repetition}",
        "DAILY": "päivittäin",
        "WEEKLY": "viikottain",
        "MONTHLY": "kuukausittain"
      }, 
      "tasks": {
        "title": "Tehtävä",
        "add": "Lisää tehtävä",
        "select": "Valitse tehtävä",
        "info": "Valitse vastaanottajille lähetettävä tehtävä"
      }
    },
    "recipients": {
      "title": "Vastaanottajat",
      "ingress": "Valitse käyttäjät ryhmistä ja/tai lisää uusia käyttäjiä syöttämällä heidän sähköpostiosoitteet.",
      "selected": "Valitut vastaanottajat",
      "group-title": "Ryhmä",
      "members-title": "Jäsenet",
      "invite-title": "Kutsu uusia jäseniä",
      "add-button": "Lisää",
      "new-members-info": "Uudet jäsenet liitetään valittuun ryhmään"
    },
    "additional-content": {
      "title": "Muut tiedot",
      "subtitle": "Uusille käyttäjille lähetettävä viesti",
      "info": "Lisätään kutsusähköpostin loppuun."
    },
    "summary": {
      "title": "Yhteenveto",
      "content": "Lähetettävä sisältö:",
      "survey": "Arvio:"
    },
    "error": {
      "title": "Virhe kutsujen luomisessa",
      "unknown": "Tuntematon virhe"
    },
    "success": {
      "title": "Kutsut luotu onnistuneesti",
      "success": "Onnistui",
      "deleted-invitations": ", poistettiin {deletedInvitations} vanhat kutstut"
    }
  }
}